import React, {useState} from "react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{name, email, message}, setState] = useState(initialState);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setState((prevState) => ({...prevState, [name]: value}));
  };
  const clearState = () => setState({...initialState});


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */
    }

    emailjs
        .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
        .then(
            (result) => {
              console.log(result.text);
              clearState();
            },
            (error) => {
              console.log(error.text);
            }
        );
  };
  return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>联系我们</h2>
                  <img src="img/ditu/wechat_black.jpg"  height="300" width="300" className="img-responsive" alt=""/>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>联系方式</h3>
                <p>
                <span>
                  <i className="fa fa-skype"></i> Skype
                </span>
                  {props.data ? props.data.skype : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                <span>
                  <i className="fa fa-qq"></i> QQ
                </span>{" "}
                  {props.data ? props.data.qq : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                <span>
                  <i className="fa fa-whatsapp"></i> WhatsAPP
                </span>{" "}
                  {props.data ? props.data.whatsapp : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
            {/*<div className="col-md-12">*/}
            {/*  <div className="row">*/}
            {/*    <div className="social">*/}
            {/*      <ul>*/}
            {/*        <li>*/}
            {/*          <a href={props.data ? props.data.facebook : "/"}>*/}
            {/*            <i className="fa fa-facebook"></i>*/}
            {/*          </a>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <a href={props.data ? props.data.twitter : "/"}>*/}
            {/*            <i className="fa fa-twitter"></i>*/}
            {/*          </a>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <a href={props.data ? props.data.youtube : "/"}>*/}
            {/*            <i className="fa fa-youtube"></i>*/}
            {/*          </a>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              Copyright &copy; 2020-2024 冀ICP备2021023464号-1 保定云合网络科技有限公司
            </p>
          </div>
        </div>
      </div>
  );
};
